import _ from "lodash";
import moment, { Moment } from "moment";
import {
  FormEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";

import {
  Action,
  AppointmentStatus,
  CreateAppointment,
  ErrorsObj,
  InputType,
  StylesEnum,
  UserType,
  EndpointGenerator,
  Appointment,
  ErrorMsg,
  ProvidedServiceTypesSpanish,
  ModalitySpanish,
  OrderEnum,
  Patient,
  GetAllPagedResult,
} from "@mapsy/shared";

import COLORS from "constants/colors";

import { CustomButton } from "./Button";
import { ModalLayout } from "layouts/ModalLayout";
import { selectSessionState } from "features/session/session.slice";
import { setToken } from "utils/setToken";
import { useAppSelector } from "hooks";
import { useAxios } from "hooks/useAxios";
import { Entity, Form } from "interfaces";
import { InputField } from "./InputField";
import { areInputsValid } from "utils/areInputsValid";
import { useAnalytics } from "hooks/useAnalytics";
import COMPONENTS from "constants/componentNames";
import { DATE_LONG_FORMAT } from "constants/defaultUserValues";
import { START_WORK_HOUR } from "pages/MyCalendar";
import { MapsySwitch } from "./MapsySwitch";
import { InfoTooltip } from "./InfoTooltip";
import { selectTherapistState } from "features/therapist/therapist.slice";
import { useSelector } from "react-redux";
import { debounce } from "utils/debounce";
import { capitalizeName } from "utils/capitalize";

export const DEFAULT_APPOINTMENT_DURATION = 60;
const AVAILABLE_HOURS_LENGTH = 15;
const PATIENTS_PER_PAGE = 20;
const DEBOUNCE_INTERVAL = 300;

function a11yProps(index: number) {
  return {
    id: `new-appointment-tabpanel-${index}`,
    "aria-controls": `new-appointment-tabpanel-${index}`,
  };
}

interface Props {
  newAppointmentDate: Moment;
  onSavedAppointment: (newAppointmentWeek: number) => void;
  onClose: () => void;
  appointmentToEdit?: Appointment;
}

interface NewAppointmentValues {
  title: string;
  day: number;
  startTime: number;
  endTime: number;
  patientId?: string;
  locationId?: string;
  providedServiceId?: string;
}

export const NewAppointmentModal: React.FC<Props> = ({
  newAppointmentDate,
  onClose,
  onSavedAppointment: onSave,
  appointmentToEdit,
}) => {
  const {
    isLoading: loadingPatients,
    errorMsg: errorMsgPatients,
    getData: getPatients,
  } = useAxios();
  const { postData, patchData, isLoading, errorMsg } = useAxios();
  const { createAnalytic } = useAnalytics();
  const { token, profileInfo } = useAppSelector(selectSessionState);
  const [patients, setPatients] = useState<Record<string, Patient>>({});
  const { therapist } = useSelector(selectTherapistState);

  const [errors, setErrors] = useState<ErrorsObj>();
  const [values, setValues] = useState<NewAppointmentValues>({
    title: appointmentToEdit?.title || "",
    day: newAppointmentDate.valueOf(),
    startTime: newAppointmentDate.valueOf(),
    endTime: moment(newAppointmentDate)
      .add(
        appointmentToEdit?.duration || DEFAULT_APPOINTMENT_DURATION,
        "minutes"
      )
      .valueOf(),
  });
  const [allDayAppointment, setAllDayAppointment] = useState(
    appointmentToEdit ? appointmentToEdit.duration === 24 * 60 : false
  );
  const [hasSucceeded, setHasSucceeded] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const tabs = useMemo<string[]>(() => {
    const defaultTabs = ["Generar cita", "Bloquear espacio"];

    if (appointmentToEdit) {
      return ["Actualizar cita"];
    }

    if (values.day < moment().valueOf()) {
      return defaultTabs.slice(1);
    }

    return defaultTabs;
  }, [appointmentToEdit, values]);
  const showBlockingInputs = useMemo(
    () => tabs.length === 1 || currentTab === 1,
    [tabs, currentTab]
  );
  const buttonLabel = useMemo(() => {
    if (appointmentToEdit) {
      return "Actualizar";
    }
    if (showBlockingInputs) {
      return "Bloquear espacio";
    }
    return "Generar cita";
  }, [appointmentToEdit, showBlockingInputs]);

  const selectedLocation = useMemo(
    () => therapist?.locations.find(({ _id }) => _id === values.locationId),
    [values.locationId]
  );

  const [patientName, setPatientName] = useState("");

  const fetchPatients = useCallback(
    async (
      id: string,
      patientName: string,
      signal: AbortSignal,
      token: string
    ) => {
      const endpoint =
        EndpointGenerator.TherapistAPI.getAllPatientsByTherapistId(id, {
          order: OrderEnum.DESC,
          orderBy: "firstName",
          limit: PATIENTS_PER_PAGE,
          page: 1,
          patientName,
        });
      const response: GetAllPagedResult<Patient> = await getPatients(endpoint, {
        signal,
        ...setToken(token),
      });

      if (!response?.results?.length) {
        return;
      }
      const { results } = response;

      setPatients((_patients) => {
        const mappedPatients: Record<string, Patient> = {};
        results.forEach((patient) => {
          mappedPatients[patient._id] = patient;
        });
        
        return {
          ..._patients,
          ...mappedPatients
        };
      });
    },
    []
  );
  const debounceFetchPatients = useMemo(
    () => debounce(DEBOUNCE_INTERVAL, fetchPatients),
    [fetchPatients]
  );

  const handleChangeTab = useCallback((e: SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  }, []);

  useEffect(() => {
    setValues((_values) => {
      const prevHour = moment(_values.startTime).get("hour");
      return {
        ..._values,
        startTime: moment(_values.day).set("hour", prevHour).valueOf(),
        endTime: moment(_values.day)
          .set("hour", prevHour)
          .add(
            appointmentToEdit?.duration || DEFAULT_APPOINTMENT_DURATION,
            "minutes"
          )
          .valueOf(),
      };
    });
  }, [values.day, appointmentToEdit]);

  useEffect(() => {
    if (!selectedLocation) {
      return;
    }
    if (
      !values.providedServiceId ||
      !selectedLocation.providedServices.find(
        ({ _id }) => _id === values.providedServiceId
      )
    ) {
      setValues((_values) => ({
        ..._values,
        providedServiceId: selectedLocation.providedServices[0]._id,
      }));
    }
  }, [selectedLocation]);

  const handleChangePatientName = useCallback((value: string) => {
    setPatientName(value);
  }, []);

  useEffect(() => {
    if (!patientName || !profileInfo?.id || !token) {
      setValues((_values) => ({
        ..._values,
        patientId: "",
      }));
      return;
    }

    if ( // this prevents to make the requests again when a patient is selected
      Object.values(patients).find(
        (patient) =>
          patientName ===
          capitalizeName([
            patient.firstName,
            patient.middleName,
            patient.lastName,
          ])
      )
    ) {
      return;
    }

    const controller = new AbortController();
    const { signal } = controller;

    debounceFetchPatients(profileInfo.id, patientName, signal, token);

    return () => {
      controller.abort();
    };
  }, [patientName]);

  const patientAppointmentInputs: Form = useMemo(
    () => [
      {
        propertyName: "patientId",
        label: "Nombre del paciente",
        inputType: InputType.Autocomplete,
        placeholder: "Escribe el nombre de tu paciente...",
        options: Object.values(patients).map((patient) => ({
          label: capitalizeName([
            patient.firstName,
            patient.middleName,
            patient.lastName,
          ]),
          value: patient._id,
        })),
        inputValue: patientName,
        onInputChange: handleChangePatientName,
        loading: loadingPatients,
        required: true,
        validation: {
          isRequired: true,
          minLength: 2,
          maxLength: 50,
        },
      },
      {
        propertyName: "locationId",
        label: "Consultorio",
        inputType: InputType.Select,
        autoFocus: false,
        menuItems:
          therapist?.locations.map((loc) => ({
            label: `${ModalitySpanish[loc.modality]}: ${loc.street} ${loc.externalNo}, ${loc.community}.`,
            value: loc._id,
          })) || [],
        validation: {
          isRequired: true,
        },
      },
      {
        propertyName: "providedServiceId",
        label: "Tipo de servicio",
        inputType: InputType.Select,
        autoFocus: false,
        menuItems:
          selectedLocation?.providedServices.map(
            ({ _id, serviceType, price, currency }) => ({
              label: `${ProvidedServiceTypesSpanish[serviceType]} - $${price}(${currency})`,
              value: _id,
            })
          ) || [],
        validation: {
          isRequired: true,
        },
        gridSize: {
          xs: 12,
        },
      },
      {
        propertyName: "day",
        label: "Día de la cita",
        inputType: InputType.Select,
        autoFocus: false,
        menuItems: Array.from({ length: 7 }, (_, i) => {
          const nextDay = moment(newAppointmentDate).add(i, "day");
          return {
            label: nextDay.format(DATE_LONG_FORMAT),
            value: nextDay.valueOf(),
          };
        }),
      },
      {
        propertyName: "startTime",
        label: "Hora de inicio de la cita",
        inputType: InputType.Select,
        autoFocus: false,
        menuItems: Array.from({ length: AVAILABLE_HOURS_LENGTH }, (_, i) => {
          const daySelected = values.day;
          const time = moment(daySelected).set("hour", i + START_WORK_HOUR);
          return {
            label: time.format("HH:mm"),
            value: time.valueOf(),
          };
        }),
      },
      {
        propertyName: "endTime",
        label: "Hora de fin de la cita",
        inputType: InputType.Select,
        autoFocus: false,
        menuItems: Array.from({ length: AVAILABLE_HOURS_LENGTH }, (_, i) => {
          const daySelected = values.day;
          const time = moment(daySelected)
            .set("hours", i + START_WORK_HOUR)
            .add(DEFAULT_APPOINTMENT_DURATION, "minutes");
          return {
            label: time.format("HH:mm"),
            value: time.valueOf(),
          };
        }),
      },
    ],
    [
      values.day,
      newAppointmentDate,
      allDayAppointment,
      therapist,
      selectedLocation,
      patients,
      patientName,
      loadingPatients,
    ]
  );

  const blockingAppointmentInputs: Form = useMemo(
    () => [
      {
        propertyName: "title",
        label: "Título del evento",
        inputType: InputType.Text,
        placeholder: "Cita con ...",
        validation: {
          isRequired: true,
          minLength: 2,
          maxLength: 50,
        },
      },
      {
        propertyName: "day",
        label: "Día del evento",
        inputType: InputType.Select,
        autoFocus: false,
        menuItems: Array.from({ length: 7 }, (_, i) => {
          const nextDay = moment(newAppointmentDate).add(i, "day");
          return {
            label: nextDay.format(DATE_LONG_FORMAT),
            value: nextDay.valueOf(),
          };
        }),
      },
      {
        propertyName: "startTime",
        label: "Hora de inicio del evento",
        inputType: InputType.Select,
        autoFocus: false,
        menuItems: Array.from({ length: AVAILABLE_HOURS_LENGTH }, (_, i) => {
          const daySelected = values.day;
          const time = moment(daySelected).set("hour", i + START_WORK_HOUR);
          return {
            label: time.format("HH:mm"),
            value: time.valueOf(),
          };
        }),
        disabled: allDayAppointment,
      },
      {
        propertyName: "endTime",
        label: "Hora de fin del evento",
        inputType: InputType.Select,
        autoFocus: false,
        menuItems: Array.from({ length: AVAILABLE_HOURS_LENGTH }, (_, i) => {
          const daySelected = values.day;
          const time = moment(daySelected)
            .set("hours", i + START_WORK_HOUR)
            .add(DEFAULT_APPOINTMENT_DURATION, "minutes");
          return {
            label: time.format("HH:mm"),
            value: time.valueOf(),
          };
        }),
        disabled: allDayAppointment,
      },
    ],
    [values.day, newAppointmentDate, allDayAppointment]
  );

  const handleChange = useCallback(
    (propertyName: keyof NewAppointmentValues | string, value: any) => {
      let parsedValue: number | string;
      switch (propertyName) {
        case "title":
          parsedValue = value.toString();
          break;
        case "propertyName":
          parsedValue = value.toString();
          break;
        default:
          parsedValue = !isNaN(+value) ? +value : value?.toString();
          break;
      }

      setValues((_values) => ({ ..._values, [propertyName]: parsedValue }));
    },
    []
  );

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();

      const therapistId =
        profileInfo?.type === UserType.Therapist && profileInfo?.id;

      if (!therapistId) {
        return;
      }

      if (
        !areInputsValid({
          inputs:
            tabs.length === 1 || currentTab === 1
              ? blockingAppointmentInputs
              : patientAppointmentInputs,
          values: values as unknown as Entity,
          setErrors,
        })
      ) {
        return;
      }

      const { endTime, startTime, patientId, locationId, providedServiceId } =
        values;
      if (endTime < startTime) {
        setErrors({ endTime: ErrorMsg.InvalidTimes });
        return;
      }

      const isBlocking = !(locationId && patientId);
      const createAppointment: CreateAppointment = {
        date: startTime,
        therapistId,
        duration: moment(values.endTime).diff(values.startTime, "minutes"),
        isBlocking,
        appointmentStatus: isBlocking
          ? AppointmentStatus.Confirmed
          : AppointmentStatus.Pending,
      };

      if (isBlocking) {
        if (values.title) {
          createAppointment.title = values.title;
        }

        if (allDayAppointment) {
          createAppointment.date = moment(values.endTime)
            .startOf("day")
            .valueOf();
          createAppointment.duration = 24 * 60;
        }
      }

      if (!isBlocking) {
        createAppointment.patientId = patientId;
        createAppointment.locationId = locationId;
        createAppointment.providedService =
          selectedLocation?.providedServices.find(
            ({ _id }) => _id === providedServiceId
          );
      }

      let response: { _id: string } | string;

      if (appointmentToEdit) {
        response = await patchData(
          EndpointGenerator.AppointmentAPI.urlById(appointmentToEdit._id),
          createAppointment,
          setToken(token)
        );
      } else {
        response = await postData(
          EndpointGenerator.AppointmentAPI.baseURL,
          createAppointment,
          setToken(token),
          {
            409: ErrorMsg.ManualAppointmentDuplicated,
          }
        );
      }

      createAnalytic({
        action: Action.SUBMIT,
        componentName: COMPONENTS.NEW_APPOINTMENT_MODAL,
        data: {
          createAppointment,
          response,
          updateAppointment: Boolean(appointmentToEdit),
        },
      });

      if (response) {
        setHasSucceeded(true);
        onSave(newAppointmentDate.week());
        onClose();
      }
    },
    [
      newAppointmentDate,
      values,
      allDayAppointment,
      appointmentToEdit,
      selectedLocation,
      tabs,
      currentTab,
      patientAppointmentInputs,
      blockingAppointmentInputs,
    ]
  );

  return (
    <ModalLayout
      isOpen={Boolean(newAppointmentDate)}
      onClose={onClose}
      maxWidthContainer="sm"
    >
      <form onSubmit={handleSubmit}>
        <Grid container sx={{ padding: 2, gap: { md: 1, xs: 0.5 } }}>
          <Grid item xs={12}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                py: 0,
                "& .MuiTabs-scroller": {
                  overflowX: "auto !important",
                },
              }}
            >
              <Tabs
                value={currentTab}
                onChange={handleChangeTab}
                aria-label="new appointment tabs"
                className="transparent-scroll"
              >
                {tabs.map((tab, i) => (
                  <Tab
                    key={`new-appointment-tabpanel-${i}-${tab}`}
                    label={
                      <Typography
                        sx={{ color: COLORS.BLUE_1, fontWeight: 500 }}
                        variant="h5"
                      >
                        {tab}
                      </Typography>
                    }
                    {...a11yProps(i)}
                    sx={{
                      fontSize: { md: "0.9rem", xs: "0.7rem" },
                      textTransform: "inherit",
                      "&.Mui-selected": {
                        color: COLORS.BLUE_1,
                        borderBottomColor: COLORS.BLUE_1,
                      },
                    }}
                  />
                ))}
              </Tabs>
            </Box>
          </Grid>

          {(showBlockingInputs
            ? blockingAppointmentInputs
            : patientAppointmentInputs
          ).map(({ gridSize = { xs: 12 }, propertyName, ...rest }, i) => (
            <Grid
              xs={12}
              id={`row-input-container-${propertyName}-${i}`}
              item
              key={`row-input-container-${propertyName}-${i}`}
              sx={{ px: 1, my: 1.5 }}
            >
              <Grid container sx={{ flexDirection: "column" }}>
                <Grid item xs={12}>
                  <Typography>{rest.label}:</Typography>
                </Grid>

                <Grid item xs={12} md={12}>
                  <InputField
                    backgroundMode="transparent"
                    propertyName={propertyName}
                    value={_.get(values, propertyName, "")}
                    handleChange={handleChange}
                    helperText={errors && errors[propertyName]}
                    {...rest}
                    label=""
                  />
                </Grid>
              </Grid>
            </Grid>
          ))}

          {currentTab === 1 && (
            <>
              <Grid xs={12} item sx={{ px: 1, my: 1.5 }}>
                <Grid container sx={{ flexDirection: "column" }}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{ display: "flex", gap: 2, alignItems: "center" }}
                  >
                    <MapsySwitch
                      checked={allDayAppointment}
                      onChange={(e, checked) => setAllDayAppointment(checked)}
                    />
                    <Typography>Todo el día</Typography>
                    <InfoTooltip title="¿El evento dura toda tu jornada laboral?" />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}

          {(errorMsg || errorMsgPatients) && (
            <Grid item xs={12}>
              <Typography>{errorMsg || errorMsgPatients}</Typography>
            </Grid>
          )}

          <Grid
            item
            xs={12}
            sx={{ mt: 3, display: "flex", justifyContent: "right" }}
          >
            <CustomButton
              customStyle={StylesEnum.primary}
              sx={{ px: 1 }}
              hasSucceeded={hasSucceeded}
              type="submit"
              isLoading={isLoading}
            >
              {buttonLabel}
            </CustomButton>
          </Grid>
        </Grid>
      </form>
    </ModalLayout>
  );
};
