import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { COLORS, EndpointGenerator, OrderEnum, Patient } from "@mapsy/shared";
import { PatientsTable } from "components/organisms/PatientsTable";
import { useAxios } from "hooks/useAxios";
import { useSelector } from "react-redux";
import { selectSessionState } from "features/session/session.slice";
import { setToken } from "utils/setToken";
import { useParams } from "react-router-dom";
import { CustomLink } from "components/atoms/Link";
import { capitalizeName } from "utils/capitalize";

const PATIENTS_PER_PAGE = 20;

const MyPatients: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { patientId } = useParams(); //id of selected patient to list history
  const {
    isLoading: loading,
    errorMsg,
    getData,
  } = useAxios({ loadingDefault: true });
  const { token, profileInfo } = useSelector(selectSessionState);
  const [page, setPage] = useState(1);
  const [patients, setPatients] = useState<Record<number, Patient[]>>({});
  const flatPatients = useMemo(
    () => Object.values(patients).flat(),
    [patients]
  );
  const [totalPages, setTotalPages] = useState(1);

  const [currentPatientName, setCurrentPatientName] = useState("");

  const fetchPatients = useCallback(
    async (id: string, page: number, signal: AbortSignal, token: string) => {
      const endpoint =
        EndpointGenerator.TherapistAPI.getAllPatientsByTherapistId(id, {
          order: OrderEnum.DESC,
          orderBy: "firstName",
          limit: PATIENTS_PER_PAGE,
          page,
        });
      const response = await getData(endpoint, { signal, ...setToken(token) });

      if (!response?.results?.length) {
        return;
      }
      const { results, totalPages } = response;
      setTotalPages(totalPages);

      setPatients((_patients) => ({ ..._patients, [page]: results }));
    },
    []
  );

  const handleNextPage = useCallback(() => {
    if (totalPages === page) {
      return;
    }
    setPage(page + 1);
  }, [page, totalPages]);

  useEffect(() => {
    if (!token || !profileInfo?.id) {
      return;
    }

    const controller = new AbortController();
    const { signal } = controller;

    fetchPatients(profileInfo.id, page, signal, token);
    return () => {
      controller.abort();
    };
  }, [page, token, profileInfo]);

  useEffect(() => {
    if (!patientId) {
      return;
    }
    const foundPatient = flatPatients.find(({ _id }) => _id === patientId);
    if (!foundPatient) {
      return;
    }
    setCurrentPatientName(
      capitalizeName([foundPatient.firstName, foundPatient.lastName])
    );
  }, [flatPatients, patientId]);

  return (
    <Container
      sx={{
        px: { xs: 1.5, md: 3 },
        py: { xs: 2, md: 3 },
        position: "relative",
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          sm={9}
          md={10}
          sx={{
            p: 1,
            display: "flex",
            alignItems: "start",
            justifyContent: "center",
            flexDirection: "column",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {!patientId ? (
            <Typography variant="h3">Mis pacientes</Typography>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: { md: 2, xs: 1 },
                width: "min-content",
              }}
            >
              <CustomLink to={"/therapist/my_patients"} underline>
                <Typography variant="h3">Mis pacientes</Typography>
              </CustomLink>
              <ArrowForwardIosIcon
                sx={{ fontSize: { md: "1.2rem", xs: "1rem" } }}
              />
              <Typography
                variant="h3"
                sx={{
                  width: { xs: "45%", md: "100%" },
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                Historial de {currentPatientName}
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid
          item
          sx={{
            borderBottom: `solid 3px ${COLORS.BLUE_1}`,
            my: 2,
          }}
          md={6}
          xs={12}
        ></Grid>
        {!patientId && (
          <>
            <Grid
              item
              xs={12}
              sx={{ my: { md: 3, xs: 1 }, textAlign: "center" }}
            >
              {loading ? (
                <CircularProgress sx={{ color: COLORS.BLUE_1 }} />
              ) : (
                <PatientsTable
                  patients={flatPatients}
                  onNextPage={handleNextPage}
                />
              )}
            </Grid>
            {errorMsg && (
              <Grid
                item
                xs={12}
                sx={{ my: { md: 3, xs: 1 }, textAlign: "center" }}
              >
                <Typography>{errorMsg}</Typography>
              </Grid>
            )}
          </>
        )}
        {children}
      </Grid>
    </Container>
  );
};

export default MyPatients;
