import {
  APINames,
  GetAllTherapistByTopicParent,
  GetAllTherapistByTopicSiblings,
  OrderEnum,
  Therapist,
} from "../..";
import BaseAPI from "./BaseEndpointAPI";

class TherapistAPI extends BaseAPI {
  constructor() {
    super(APINames.THERAPIST);
  }

  changePasswordById(id: string) {
    return `${this.baseURL}/change-password/${id}`;
  }

  getAll(params: {
    orderBy: keyof Therapist | string;
    order: OrderEnum;
    page: number;
    limit: number;
    topicId?: string | string[];
    therapistId?: string;
    hideInfo?: boolean;
    onlyRegistrationComplete?: boolean;
    emailOrNameRegex?: string
  }) {
    const queryString = new URLSearchParams(
      params as Record<string, any>
    ).toString();
    return `${this.baseURL}?${queryString}`;
  }

  allTherapists(params: Record<string, any>) {
    const queryString = new URLSearchParams(
      params as Record<string, any>
    ).toString();
    return `${this.baseURL}/all-therapists?${queryString}`;
  }

  parentTopic(params: GetAllTherapistByTopicParent) {
    const queryString = new URLSearchParams(
      params as Record<string, any>
    ).toString();
    return `${this.baseURL}/parent-topic?${queryString}`;
  }

  topicSibligs({ params, topicId }: GetAllTherapistByTopicSiblings) {
    const queryString = new URLSearchParams(
      params as Record<string, any>
    ).toString();
    return `${this.baseURL}/topic-siblings/${topicId}?${queryString}`;
  }
  
  overviewInfo(){
    return `${this.baseURL}/overview-info`;
  }

  validateMxTherapistById(id: string){
    return `${this.baseURL}/validate-mx-professionalId/${id}`;
  }

  getAllPatientsByTherapistId(
    id: string,
    params: {
      orderBy: keyof Therapist | string;
      order: OrderEnum;
      page: number;
      limit: number;
      patientName?: string;
    }
  ) {
    const queryString = new URLSearchParams(
      params as Record<string, any>
    ).toString();
    return `${this.baseURL}/${id}/patients?${queryString}`;
  }
}

const api = new TherapistAPI();

export default api;
