import React, { useState, useEffect, ReactNode } from "react";
import { Box } from "@mui/material";

interface Props {
  offsetToHide?: number;
  children: ReactNode;
}
const AutoHidableBox: React.FC<Props> = ({
  offsetToHide = 200,
  children,
}) => {
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setHidden(scrollPosition > offsetToHide);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box className={`auto-hidable-box ${hidden ? "hidden" : ""}`}>{children}</Box>
  );
};

export default AutoHidableBox;
