import momentTz from "moment-timezone";
import {
  MouseEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Card,
  Grid,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Helmet } from "react-helmet-async";

import {
  Action,
  Location,
  FindAvailableApppointmentsResult,
  HoursByDayHash,
  ParsedSelectedPreconfirmationTime,
  SelectedPreconfirmationTime,
  Therapist,
  EndpointGenerator,
  ModalitySpanish,
  WeekDaysEnum,
  WorkSchedule,
  StylesEnum,
} from "@mapsy/shared";
import COLORS from "constants/colors";
import { selectReasonState } from "features/reason/reasonSice";
import { useAppSelector } from "hooks";
import { capitalizeName } from "utils/capitalize";
import { AvailableTimeTable } from "./AvailableTimeTable";
import { TherapistInfo } from "./TherapistInfo";
import { TherapistLocationTab } from "./TherapistLocationTab";
import { PreConfirmationModal } from "components/organisms/PreConfirmationModal";
import moment, { Moment } from "moment";
import { theme } from "theme";
import { useAxios } from "hooks/useAxios";
import COMPONENTS from "constants/componentNames";
import { selectSessionState } from "features/session/session.slice";
import { useAnalytics } from "hooks/useAnalytics";
import { ProfilePic } from "components/atoms/ProfilePic";
import { CustomLink } from "components/atoms/Link";
import { useTopics } from "hooks/useTopics";
import { TherapistTheory } from "components/atoms/therapistInfo/TherapistTheory";
import { TherapistStars } from "components/atoms/therapistInfo/TherapistStars";
import { TherapistTopics } from "components/atoms/therapistInfo/TherapistTopics";
import { SelectedAppointmentState } from "interfaces";
import { CustomButton } from "components/atoms/Button";
import AutoHidableBox from "components/atoms/AutoHidableButton";
import { useQuery } from "hooks/useQuery";

export const LIMIT_MAPPED_DAYS = 5;
export const LIMIT_MAPPED_DAYS_FOR_MOBILES = 3;
export const MIN_HOUR_OF_DAY_TO_LOOK_FOR_APPOINTMENTS = 6;

export const SELECTED_APPOINTMENT = "selected-appointment-state";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
interface Props {
  therapist: Therapist;
  isLast?: boolean;
  isSingleView?: boolean;
  fetchNextPage?: () => void;
}
export const TherapistCard: React.FC<Props> = ({
  therapist,
  isLast = false,
  fetchNextPage,
  isSingleView = false,
}) => {
  const query = useQuery();
  const {
    _id,
    firstName,
    middleName,
    lastName,
    professionalId,
    stars,
    topicsId,
    locations,
  } = therapist;
  const nav = useNavigate();
  const routerLocation = useLocation();
  const { consultingActions, askForConsultingReason } =
    useAppSelector(selectReasonState);
  const { createAnalytic } = useAnalytics();
  const { profileInfo } = useAppSelector(selectSessionState);
  useTopics(); // we load topics here too in case a user goes to therapist view directly
  const { getData, isLoading } = useAxios();
  const observerTarget = useRef(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [selectedPreconfirmationTime, setSelectedPreconfirmationTime] =
    useState<SelectedPreconfirmationTime>();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const calendarRef = useRef<HTMLDivElement | null>(null);
  const locationsTabsRef = useRef<HTMLDivElement | null>(null);
  const showCalendar = !isMobile || isSingleView;
  const showButtons = !isSingleView && isMobile;
  const showAutoHidableButton = isMobile && isSingleView;

  const borderProperties = useMemo(() => {
    if (isSingleView) {
      return {};
    }
    if (isMobile) {
      return {
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        borderRadius: "20px",
      };
    }
    return {
      border: 1,
      borderColor: COLORS.BLUE_1,
      borderRadius: "10px",
    };
  }, [isSingleView, isMobile]);

  const [selectedDate, setSelectedDate] = useState(
    moment().add(25, "hours").set("minutes", 0)
  );

  const defaultHoursByDayAndLocation = useMemo(() => {
    const _hoursByDayAndLocationHash: Record<string, HoursByDayHash> = {};
    locations.forEach(({ _id }) => {
      _hoursByDayAndLocationHash[_id] = {};
    });
    return _hoursByDayAndLocationHash;
  }, [locations]);

  const [hoursByDayAndLocationHash, setHoursByDayAndLocationHash] = useState<
    Record<string, HoursByDayHash>
  >(defaultHoursByDayAndLocation);

  const hoursByDayByLocation = useMemo(() => {
    if (locations[currentTab]?._id) {
      return hoursByDayAndLocationHash[locations[currentTab]._id];
    }
  }, [hoursByDayAndLocationHash, locations, currentTab]);

  const handleChangeTab = useCallback((e: SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  }, []);

  useEffect(() => {
    const currentTarget = observerTarget.current;
    if (!currentTarget || !isLast) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          fetchNextPage?.();
        }
      },
      { threshold: 0 }
    );

    observer.observe(currentTarget);
    return () => {
      if (currentTarget) {
        observer.unobserve(currentTarget);
      }
    };
  }, [observerTarget, isLast, fetchNextPage]);

  const fetchAvailableTimesByLocation = useCallback(
    async (location: Location, therapistId: string, selectedDate: Moment) => {
      const day = selectedDate.valueOf();
      const formattedDate = selectedDate.format("YYYY-MM-DD");
      const locationId = location._id;

      const dayOfWeek = selectedDate.get("d");
      const isWorkingDay =
        location.workSchedule[WeekDaysEnum[dayOfWeek] as keyof WorkSchedule]
          .workable;

      if (hoursByDayAndLocationHash[locationId][formattedDate]) {
        return;
      }

      if (!isWorkingDay) {
        setHoursByDayAndLocationHash((_hoursByDayAndLocation) => ({
          ..._hoursByDayAndLocation,
          [locationId]: {
            ..._hoursByDayAndLocation[locationId],
            [formattedDate]: [],
          },
        }));
        return;
      }

      const endpoint = EndpointGenerator.AppointmentAPI.availableTimeByDay({
        day,
        therapistId,
        locationId,
        timezone: momentTz.tz.guess(true),
      });
      const { availableTimes }: FindAvailableApppointmentsResult =
        await getData(endpoint);

      setHoursByDayAndLocationHash((_hoursByDayAndLocation) => ({
        ..._hoursByDayAndLocation,
        [locationId]: {
          ..._hoursByDayAndLocation[locationId],
          [formattedDate]: availableTimes,
        },
      }));
    },
    [hoursByDayAndLocationHash]
  );

  useEffect(() => {
    if (!locations[currentTab]) {
      setCurrentTab(0);
      return;
    }

    fetchAvailableTimesByLocation(
      locations[currentTab],
      therapist._id,
      selectedDate
    );
  }, [currentTab, therapist, selectedDate]);

  const handleScheduleAppointmentClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (!isSingleView) {
        nav(`/therapists/${_id}?directTo=calendar`);
        return;
      }
      calendarRef.current?.scrollIntoView({ behavior: "smooth" });
    },
    [calendarRef]
  );

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    const goToCalendar = query.get("directTo") === "calendar";
    if (goToCalendar && calendarRef) {
      timeout = setTimeout(() => {
        calendarRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [calendarRef]);

  const handleViewMoreClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      nav(`/therapists/${_id}`);
    },
    []
  );

  const handleGoToOtherLocations = useCallback(
    (e: MouseEvent<HTMLSpanElement>) => {
      e.stopPropagation();
      locationsTabsRef?.current?.scrollIntoView({ behavior: "smooth" });
    },
    [locationsTabsRef]
  );

  const handleChangeDate = useCallback((val: Moment) => {
    val.set("minutes", 0).set("seconds", 0);
    const isLessThan24Hours = val.diff(moment(), "hours") < 25;
    if (isLessThan24Hours) {
      val.add(25 - val.diff(moment(), "hours"), "hours");
    } else {
      val.set("hour", MIN_HOUR_OF_DAY_TO_LOOK_FOR_APPOINTMENTS);
    }
    setSelectedDate(val);
  }, []);

  const handleClosePreconfirmationModal = useCallback(
    (preconfirmation: ParsedSelectedPreconfirmationTime | undefined) => {
      setIsConfirmationModalOpen(false);
      setSelectedPreconfirmationTime(undefined);

      if (!preconfirmation?.hour || !preconfirmation.service) {
        return;
      }

      const patientId = profileInfo?.id;

      const { hour, service } = preconfirmation;
      const location = locations[currentTab];

      const state: SelectedAppointmentState = {
        therapist,
        location,
        hour,
        service,
        askForConsultingReason,
        reason: consultingActions.reason,
      };
      sessionStorage.setItem(SELECTED_APPOINTMENT, JSON.stringify(state));

      createAnalytic({
        action: Action.SUBMIT,
        componentName: COMPONENTS.THERAPIST_CARD,
        data: {
          therapistId: therapist._id,
          locationId: location._id,
          hour,
          service,
        },
      });

      if (!patientId) {
        nav("/users/signin?type=patient&redirectTo=/appointment/confirmation");
        return;
      }

      nav(`/appointment/confirmation`);
    },
    [
      locations,
      currentTab,
      therapist,
      currentTab,
      profileInfo?.id,
      askForConsultingReason,
      consultingActions,
    ]
  );

  useEffect(() => {
    if (!selectedPreconfirmationTime) {
      return;
    }
    setIsConfirmationModalOpen(true);
  }, [selectedPreconfirmationTime]);

  return (
    <>
      {isSingleView && (
        <Helmet>
          <title>
            {capitalizeName([firstName, middleName, lastName])} en Mapsy Ψ
          </title>
          <meta
            name="description"
            content={`Agenda con ${capitalizeName([firstName, middleName, lastName])} hoy mismo.`}
          />
          <meta
            property="og:title"
            content={`Revisa el perfil de ${capitalizeName([firstName, middleName, lastName])} en Mapsy.`}
          />
          <meta
            property="og:description"
            content={`Agenda con ${capitalizeName([firstName, middleName, lastName])} hoy mismo.`}
          />
          <meta
            property="og:image"
            content={`https://mapsy.com.mx/api/file/profile_pic_${_id}`}
          />
          <meta
            property="og:url"
            content={`https://mapsy.com.mx/therapists/${_id}`}
          />
          <meta name="robots" content="index, follow" />
        </Helmet>
      )}
      {selectedPreconfirmationTime && hoursByDayByLocation && (
        <PreConfirmationModal
          isOpen={isConfirmationModalOpen}
          onClose={handleClosePreconfirmationModal}
          location={locations[currentTab]}
          selectedTime={selectedPreconfirmationTime}
          therapist={therapist}
          hoursByDayHash={hoursByDayByLocation}
        />
      )}
      <Card
        sx={{
          boxShadow: "none",
          userSelect: "none",
          backgroundColor: "transparent",
          ...borderProperties,
        }}
        ref={observerTarget}
        className="therapist-card"
        onClick={() => {
          if (isSingleView) {
            return;
          }
          createAnalytic({
            action: Action.CLICK,
            componentName: COMPONENTS.THERAPIST_CARD,
            data: {
              therapist,
            },
          });
          nav(`/therapists/${_id}`);
        }}
      >
        <Grid container>
          {/* therapist info */}
          <Grid
            item
            sx={{
              borderRight: { md: 1 },
              borderBottom: { md: 0, xs: showCalendar ? 1 : 0 },
              borderColor: COLORS.BLUE_1,
              borderRightColor: COLORS.BLUE_1,
              padding: { md: 3, xs: 1.5 },
            }}
            sm={6}
            xs={12}
          >
            <Grid container sx={{ rowGap: "1.5rem" }}>
              <Grid item md={3} xs={4}>
                <ProfilePic {...therapist} />
              </Grid>
              <Grid
                item
                md={9}
                xs={8}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  px: { md: 2, xs: 1 },
                  rowGap: { md: 1, xs: 0.5 },
                }}
              >
                <Typography variant="h4">
                  {capitalizeName([firstName, middleName, lastName])}
                </Typography>

                {!isMobile ? (
                  <>
                    <Typography variant="body1">
                      <TherapistTheory theory={therapist.theory?.toString()} />
                    </Typography>
                    <Typography variant="body1">
                      Cédula: {professionalId}
                    </Typography>
                    <Typography variant="caption">
                      <TherapistStars stars={stars} />
                    </Typography>
                    <Typography variant="body2">
                      <TherapistTopics topicsId={topicsId} showTitle />
                    </Typography>

                    {!isSingleView && (
                      <Typography variant="body2">
                        <CustomLink
                          preventScrollReset={true}
                          to={`/therapists/${_id}`}
                          state={{ prev: `${routerLocation.pathname}` }}
                          style={{
                            fontWeight: 600,
                            color: COLORS.BLUE_1,
                          }}
                          underline
                        >
                          Ver más
                        </CustomLink>
                      </Typography>
                    )}
                  </>
                ) : (
                  <Typography variant="caption">
                    <TherapistStars stars={stars} />
                  </Typography>
                )}
              </Grid>
              {isMobile && (
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", gap: 2, flexDirection: "column" }}
                >
                  <Typography variant="body2">
                    <TherapistTheory
                      theory={therapist.theory?.toString()}
                      showTitle
                    />
                  </Typography>
                  <Typography variant="body2">
                    <Typography
                      component="span"
                      variant="body2"
                      sx={{ fontWeight: 500, color: COLORS.BLUE_1 }}
                    >
                      Cédula:{" "}
                    </Typography>
                    {professionalId}
                  </Typography>
                  <Typography variant="body2">
                    <TherapistTopics topicsId={topicsId} showTitle />
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Box
              sx={{
                my: 3,
                display: "flex",
                flexDirection: "column",
                gap: 3,
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  py: 0,
                  "& .MuiTabs-scroller": {
                    overflowX: "auto !important",
                  },
                }}
                ref={locationsTabsRef}
              >
                <Tabs
                  value={currentTab}
                  onChange={handleChangeTab}
                  aria-label="locations tabs"
                  className="transparent-scroll"
                >
                  {locations.map((location, i) => (
                    <Tab
                      key={`location-tab-${i}-${location._id}`}
                      label={`Consultorio ${i + 1}: ${
                        location.modality !== undefined
                          ? ModalitySpanish[location.modality]
                          : ""
                      }`}
                      {...a11yProps(i)}
                      sx={{
                        fontSize: { md: "0.9rem", xs: "0.7rem" },
                        textTransform: "inherit",
                      }}
                    />
                  ))}
                </Tabs>
              </Box>
              {locations.map((location, i) => (
                <TherapistLocationTab
                  key={`therapist-location-tab-${i}-${location._id}`}
                  value={currentTab}
                  location={location}
                  index={i}
                  {...a11yProps(i)}
                />
              ))}

              {isSingleView && <TherapistInfo therapist={therapist} />}
            </Box>
          </Grid>
          {/* calendar */}
          {showCalendar && (
            <Grid
              item
              sx={{
                padding: { md: "1rem", xs: "0.6rem" },
              }}
              sm={6}
              xs={12}
              ref={calendarRef}
            >
              <Box
                sx={{
                  overflowY: "auto",
                }}
                onClick={(e) => e.stopPropagation()}
              >
                {hoursByDayByLocation && (
                  <AvailableTimeTable
                    locationIndex={currentTab}
                    location={locations[currentTab]}
                    therapist={therapist}
                    hoursByDayHash={hoursByDayByLocation}
                    setSelectedPreconfirmationTime={
                      setSelectedPreconfirmationTime
                    }
                    selectedDate={selectedDate}
                    onChangeSelectedDate={handleChangeDate}
                    isLoading={isLoading}
                    isSingleView={isSingleView}
                    handleGoToOtherLocations={handleGoToOtherLocations}
                  />
                )}
              </Box>
            </Grid>
          )}
          {showButtons && (
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: { md: "right", xs: "center" },
                gap: 2,
                py: 2,
              }}
            >
              <CustomButton
                customStyle={StylesEnum.primary}
                sx={{
                  padding: { md: "12px 48px", xs: "8px 16px" },
                  borderRadius: "8px",
                }}
                onClick={handleScheduleAppointmentClick}
              >
                Agendar Cita
              </CustomButton>
              <CustomButton
                customStyle={StylesEnum.secondary}
                sx={{
                  padding: { md: "12px 48px", xs: "8px 16px" },
                  borderRadius: "8px",
                }}
                onClick={handleViewMoreClick}
              >
                Ver más
              </CustomButton>
            </Grid>
          )}
        </Grid>
      </Card>
      {showAutoHidableButton && (
        <AutoHidableBox
          offsetToHide={calendarRef.current?.getBoundingClientRect().top}
        >
          <CustomButton
            customStyle={StylesEnum.primary}
            sx={{
              padding: { md: "12px 48px", xs: "8px 16px" },
              borderRadius: "8px",
              width: "calc(100vw - 20px)",
            }}
            onClick={handleScheduleAppointmentClick}
          >
            Agendar Cita
          </CustomButton>
        </AutoHidableBox>
      )}
    </>
  );
};
