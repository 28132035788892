import { Navigate, Outlet, createBrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import { AnalyticsVisitProvider } from "providers/AnalyticsVisitProvider";
import { AppointmentConfirmation } from "pages/AppointmentConfirmation";
import { ConfirmedAppointment } from "pages/ConfirmedAppointment";
import { ForgotPassword } from "pages/ForgotPassword";
import { GetStarted } from "pages/GetStarted";
import { Home } from "pages/Home";
import { Join } from "pages/Join";
import { LayoutWithLogo } from "layouts/LayoutWithLogo";
import { LegalDocuments } from "pages/LegalDocuments";
import { LegalDocumentsTypes } from "enums/legaldocuments.enum";
import { MainLayout } from "layouts/MainLayout";
import { NotFound } from "pages/NotFound";
import { PatientSessionProvider } from "providers/PatientSessionProvider";
import { PublicLayout } from "layouts/PublicLayout";
import { Reason } from "pages/Reason";
import { ResetPassword } from "pages/ResetPassword";
import { SessionProvider } from "providers/SessionProvider";
import { SignInAndUp } from "pages/SignInAndUp";
import { TherapistSessionProvider } from "providers/TherapistSessionProvider";
import { UserType } from "@mapsy/shared";
import GoogleOAuthSuccessRedirect from "pages/GoogleOAuthSuccessRedirect";
import MyAppointmentsPatient from "pages/MyAppointmentsPatient";
import MyCalendar from "pages/MyCalendar";
import MyProfile from "pages/MyProfile";
import Review from "pages/Review";
import SuggestedPsychologists from "../pages/SuggestedPsychologists";
import TherapistPage from "pages/TherapistPage";
import { Contact } from "pages/Contact";
import { SocketProvider } from "providers/SocketProvider";
import { AboutUs } from "pages/AboutUs";
import TitleUpdater from "components/atoms/TitleUpdater";
import { ImageCacheProvider } from "providers/ImageCacheProvider";
import { PromotionProvider } from "providers/PromotionsProvider";
import { ValidateEmail } from "pages/ValidateEmail";
import { ErrorBoundary } from "components/organisms/ErrorBoundary";
import { FAQPage } from "pages/FAQPage";
import MyPatients from "pages/MyPatients";
import { PatientsAppointmentsHistory } from "pages/PatientsAppointmentsHistory";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AnalyticsVisitProvider>
        <SessionProvider>
          <SocketProvider>
            <ImageCacheProvider>
              <PromotionProvider>
                <HelmetProvider>
                  <TitleUpdater />
                  <MainLayout />
                </HelmetProvider>
              </PromotionProvider>
            </ImageCacheProvider>
          </SocketProvider>
        </SessionProvider>
      </AnalyticsVisitProvider>
    ),
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "/",
        element: <PublicLayout />,
        children: [
          {
            element: <Home />,
            index: true,
          },
          {
            path: "reason",
            element: <Reason />,
          },
          {
            path: "suggested-therapists",
            element: <SuggestedPsychologists />,
          },
          {
            path: "all-therapists",
            element: <SuggestedPsychologists showAllTherapists />,
          },
          {
            path: "therapists/:therapistId",
            element: <TherapistPage />,
          },
          {
            path: "join",
            element: <Join />,
          },
          {
            path: "terms-and-conditions",
            element: (
              <LegalDocuments
                legalDocumentType={LegalDocumentsTypes.TERMS_AND_CONDITIONS}
              />
            ),
          },
          {
            path: "privacy-policy",
            element: (
              <LegalDocuments
                legalDocumentType={LegalDocumentsTypes.PRIVACY_POLICY}
              />
            ),
          },
          {
            path: "contact",
            element: <Contact />,
          },
          {
            path: "faq",
            element: <FAQPage />,
          },
          {
            path: "about-us",
            element: <AboutUs />,
          },
          {
            path: "*",
            element: <NotFound />,
          },
        ],
      },
      {
        path: "/users",
        element: (
          <LayoutWithLogo>
            <Outlet />
          </LayoutWithLogo>
        ),
        children: [
          {
            path: "signin",
            element: <SignInAndUp />,
          },
          {
            path: "signup",
            element: <SignInAndUp isSignUp />,
          },
          {
            path: "google-oauth-success-redirect",
            element: <GoogleOAuthSuccessRedirect />,
          },
          {
            path: "forgot-password",
            element: <ForgotPassword />,
          },
          {
            path: "reset-password/:token",
            element: <ResetPassword />,
          },
          {
            path: "validate-email/:token",
            element: <ValidateEmail />,
          },
          {
            path: "*",
            element: <Navigate to="signin" replace />,
          },
        ],
      },
      {
        path: "get-started",
        element: <GetStarted />,
      },
      {
        path: "/appointment",
        element: (
          <LayoutWithLogo>
            <Outlet />
          </LayoutWithLogo>
        ),
        children: [
          {
            path: "confirmation",
            element: <AppointmentConfirmation />,
          },
        ],
      },
      {
        path: "/therapist",
        element: <TherapistSessionProvider />,
        children: [
          {
            path: "",
            element: <Navigate to="my_calendar" replace />,
          },
          {
            path: "my_calendar",
            element: <MyCalendar />,
          },
          {
            path: "my_patients",
            element: (
              <MyPatients>
                <Outlet />
              </MyPatients>
            ),
            children: [
              {
                path: ":patientId/history",
                element: <PatientsAppointmentsHistory />,
              },
            ],
          },
          {
            path: "my_profile",
            element: <MyProfile userType={UserType.Therapist} />,
          },
          {
            path: "change-password",
            element: <ResetPassword shouldChangePwd />,
          },
          {
            path: "my_locations",
            element: <MyProfile userType={UserType.Therapist} showLocations />,
          },
        ],
      },
      {
        path: "/patient",
        element: <PatientSessionProvider />,
        children: [
          {
            path: "my_profile",
            element: <MyProfile userType={UserType.Patient} />,
          },
          {
            path: "my_appointments",
            index: true,
            element: <MyAppointmentsPatient />,
          },
          {
            path: "change-password",
            element: <ResetPassword shouldChangePwd />,
          },
          {
            path: "confirmed-appointment/:appointmentId",
            element: <ConfirmedAppointment />,
          },
          {
            path: "review",
            element: <Review />,
          },
        ],
      },
      {
        path: "/promotion",
        element: <PublicLayout />,
        children: [
          {
            path: "100-mxn-discount/terms-and-conditions",
            element: (
              <LegalDocuments
                legalDocumentType={
                  LegalDocumentsTypes.PROMOTION_100_MXN_DISCOUNT
                }
              />
            ),
          },
          {
            path: "buen-fin-2024/terms-and-conditions",
            element: (
              <LegalDocuments
                legalDocumentType={LegalDocumentsTypes.PROMOTION_BUEN_FIN}
              />
            ),
          },
        ],
      },
    ],
  },
]);

export default router;
