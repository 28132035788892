import {
  SelectedPreconfirmationTime,
  Action,
  HoursByDayHash,
  Location,
  Therapist,
} from "@mapsy/shared";
import {
  TableRow,
  TableCell,
  Typography,
  Box,
  CircularProgress,
  TableBody,
} from "@mui/material";
import COMPONENTS from "constants/componentNames";
import { defaultService } from "constants/defaultUserValues";
import { useAnalytics } from "hooks/useAnalytics";
import moment, { Moment } from "moment";
import React, { Dispatch, SetStateAction, useCallback, useMemo } from "react";

interface Props {
  isLoading: boolean;
  hoursByDayHash: HoursByDayHash;
  location: Location;
  therapist: Therapist;
  selectedDate: Moment;
  setSelectedPreconfirmationTime: Dispatch<
    SetStateAction<SelectedPreconfirmationTime | undefined>
  >;
}

export const AvailableTimeTableBody: React.FC<Props> = ({
  isLoading,
  selectedDate,
  hoursByDayHash,
  location,
  therapist,
  setSelectedPreconfirmationTime,
}) => {
  const { createAnalytic } = useAnalytics();
  const availableTimes = useMemo(
    () => hoursByDayHash?.[selectedDate.format("YYYY-MM-DD")],
    [hoursByDayHash, selectedDate]
  );

  const handleSelectedTime = useCallback(
    (selectedTime: SelectedPreconfirmationTime) => {
      setSelectedPreconfirmationTime(selectedTime);

      const { hour, service } = selectedTime;
      createAnalytic({
        action: Action.CLICK,
        componentName: COMPONENTS.AVAILABLE_TIME_TABLE,
        data: {
          therapistId: therapist._id,
          locationId: location._id,
          hour: hour.valueOf(),
          service,
        },
      });
    },
    [therapist, location, setSelectedPreconfirmationTime, createAnalytic]
  );

  if (!isLoading && availableTimes?.length === 0) {
    return (
      <TableBody>
        <TableRow>
          <TableCell
            sx={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="caption">
              No hay horarios disponibles para este día.
            </Typography>
          </TableCell>
          <TableCell
            sx={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="caption">
              Elige otro día o consultorio para visualizar los horarios
              disponibles.
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  if (availableTimes?.length) {
    return (
      <TableBody
        sx={{
          display: { md: "table-row-group", xs: "flex", flexWrap: "wrap" },
        }}
      >
        {availableTimes.map((hour, i) => {
          const hourMoment = moment(hour);
          const day = selectedDate.valueOf();
          return (
            <TableRow
              key={`table-body-row-${i}`}
              sx={{
                display: { md: "table-row", xs: "block" },
              }}
            >
              <TableCell
                key={`table-body-cell-${i}-weekday-${day}`}
                onClick={() =>
                  handleSelectedTime({
                    day: moment(day),
                    hour: moment(hourMoment),
                    service: location.providedServices[0] || defaultService,
                  })
                }
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  py: { md: 1.5, xs: 1 },
                  px: { md: 1, xs: 0.5 },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    height: "100%",
                    borderRadius: "5px",
                    padding: {
                      md: "0.6rem 1.2rem",
                      xs: "0.3rem 0.6rem",
                    },
                  }}
                  className={`time-cell-content available`}
                >
                  <Typography variant="caption" noWrap>
                    {hourMoment.format("hh:mm a")}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  }

  return (
    <TableBody>
      <TableRow
        sx={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          padding: { md: 1, xs: 0 },
        }}
      >
        <TableCell>
          <CircularProgress />
        </TableCell>
      </TableRow>
    </TableBody>
  );
};
