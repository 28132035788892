import moment, { Moment } from "moment";
import { Dispatch, SetStateAction, useMemo, MouseEvent } from "react";
import {
  Box,
  Paper,
  styled,
  Table,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  Grid,
  alpha,
} from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import COLORS from "constants/colors";
import { capitalizeName } from "utils/capitalize";
import {
  Currency,
  Location,
  WorkSchedule,
  Therapist,
  ProvidedService,
  ProvidedServiceTypes,
  HoursByDayHash,
  SelectedPreconfirmationTime,
  WeekDaysEnum,
  ModalitySpanish,
  Modality,
} from "@mapsy/shared";
import { theme } from "theme";
import { AvailableTimeTableBody } from "components/atoms/AvailableTimeTableBody";

interface Props {
  locationIndex: number;
  location: Location;
  therapist: Therapist;
  hoursByDayHash: HoursByDayHash;
  setSelectedPreconfirmationTime: Dispatch<
    SetStateAction<SelectedPreconfirmationTime | undefined>
  >;
  selectedDate: Moment;
  onChangeSelectedDate: (date: Moment) => void;
  isLoading: boolean;
  isSingleView: boolean;
  handleGoToOtherLocations: (e: MouseEvent<HTMLSpanElement>) => void;
}

const ServerDay = (location: Location) => (props: PickersDayProps<Moment>) => {
  const { day, outsideCurrentMonth, ...other } = props;

  const dayOfWeek = day.get("d");
  const isWorkingDay =
    location.workSchedule[WeekDaysEnum[dayOfWeek] as keyof WorkSchedule]
      .workable && day.isAfter(moment());

  return (
    <PickersDay
      {...other}
      sx={
        isWorkingDay
          ? {
              border: "1px solid",
              borderColor: COLORS.BLUE_1,
              borderRadius: "50%",
              color: COLORS.BLUE_1,
              backgroundColor: alpha(COLORS.BLUE_2, 0.3),
            }
          : { color: "gray" }
      }
      outsideCurrentMonth={outsideCurrentMonth}
      day={day}
    />
  );
};

//@TODO Remove this: This is not ideal, but old therapists has different schemas.
export const defaultService: Partial<ProvidedService> = {
  _id: "000",
  currency: Currency.MXN,
  price: 400,
  serviceType: ProvidedServiceTypes.Individual,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: COLORS.BLUE_1,
    borderColor: COLORS.BLUE_1,
    borderWidth: 2,
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: { md: "0.8rem", xs: "0.6rem" },
  },
}));

export const AvailableTimeTable: React.FC<Props> = ({
  locationIndex,
  location,
  therapist,
  hoursByDayHash,
  setSelectedPreconfirmationTime,
  selectedDate,
  onChangeSelectedDate,
  isSingleView,
  isLoading,
  handleGoToOtherLocations,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const totalOfLocations = useMemo(
    () => therapist.locations.length,
    [therapist.locations]
  );

  return (
    <>
      <Typography
        variant="caption"
        sx={{ textAlign: "center", fontWeight: 500, mb: { md: 2, xs: 1 } }}
        component="p"
      >
        Horarios disponibles del consultorio {locationIndex + 1} (
        {ModalitySpanish[location.modality]}){" "}
        {location.modality === Modality.Presencial
          ? `ubicado en ${location.community}, ${location.city}`
          : ""}
        .
      </Typography>
      <Box sx={{ mb: { md: 2, xs: 1 } }}>
        <Typography variant="caption" component="p">
          Para agendar, elige el día y horario que más te convengan.
        </Typography>
        {totalOfLocations > 1 && (
          <Typography variant="caption" component="p">
            Si ninguno de los horarios te acomoda, este terapeuta ofrece
            horarios en {" "}
            <Typography
              variant="caption"
              onClick={(e) => {
                if (!isMobile) return;
                handleGoToOtherLocations(e);
              }}
              sx={{ textDecoration: isMobile ? "underline" : "none" }}
            >
              otros consultorios
            </Typography>
            .
          </Typography>
        )}
      </Box>
      <Grid container>
        <Grid item md={isSingleView ? 12 : 8} xs={12}>
          <DateCalendar
            defaultValue={selectedDate}
            disablePast
            value={selectedDate}
            maxDate={moment().add(3, "months")}
            onChange={onChangeSelectedDate}
            slots={{
              day: ServerDay(location),
            }}
            views={["day", "month"]}
          />
        </Grid>
        <Grid item md={isSingleView ? 12 : 4} xs={12}>
          <TableContainer
            component={Paper}
            className="transparent-scroll"
            sx={{
              backgroundColor: "transparent",
              boxShadow: "none",
              overflowY: "auto",
              maxHeight: isSingleView && !isMobile ? 800 : 500,
              th: {
                backgroundColor: COLORS.WHITE,
              },
            }}
          >
            <Table
              aria-label="simple table"
              className="available-time-table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    key={`table-head-row-${selectedDate.valueOf()}`}
                    sx={{
                      padding: { md: 1, xs: 0 },
                    }}
                  >
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        sx={{ fontWeight: 500, mb: { md: 1, xs: 0 } }}
                        variant="body2"
                      >
                        {capitalizeName(selectedDate.format("dddd"))}
                      </Typography>
                    </Box>
                    <Typography
                      fontWeight={500}
                      variant="caption"
                      sx={{ whiteSpace: { md: "wrap", xs: "nowrap" } }}
                    >
                      {`${selectedDate.format("D")} ${selectedDate.format(isMobile ? "MMM" : "MMMM")}`}
                    </Typography>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <AvailableTimeTableBody
                therapist={therapist}
                location={location}
                isLoading={isLoading}
                selectedDate={selectedDate}
                setSelectedPreconfirmationTime={setSelectedPreconfirmationTime}
                hoursByDayHash={hoursByDayHash}
              />
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};
