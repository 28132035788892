import _ from "lodash";
import React, { useEffect, useRef } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { COLORS, Patient, TableColumns } from "@mapsy/shared";
import { capitalizeName } from "utils/capitalize";
import moment from "moment";
import { CustomLink } from "components/atoms/Link";

const columns: TableColumns<Patient> = [
  {
    title: "Nombre",
    propName: "firstName",
    compute({ firstName, middleName, lastName }) {
      return capitalizeName([firstName, middleName, lastName]);
    },
  },
  {
    title: "Número",
    propName: "phone.number",
    compute(data) {
      return `(${data.phone?.dial_code})${data.phone?.number}`;
    },
  },
  {
    title: "Correo",
    propName: "email",
  },
  {
    title: "Edad",
    propName: "birthDate",
    compute({ birthDate }) {
      return `${moment().diff(birthDate, "years")} años`;
    },
  },
  {
    title: "Historial de citas",
    propName: "appointments-history",
    compute({ _id }) {
      return (
        <CustomLink
          to={`${_id}/history`}
          underline
          style={{ color: COLORS.BLUE_1 }}
        >
          Ver historial
        </CustomLink>
      );
    },
  },
];

const PatientsTableBody: React.FC<{
  patients: Patient[];
  onNextPage: () => void;
}> = ({ patients, onNextPage }) => {
  const observerTarget = useRef(null);

  useEffect(() => {
    const currentTarget = observerTarget.current;
    if (!currentTarget) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          onNextPage();
        }
      },
      { threshold: 0 }
    );

    observer.observe(currentTarget);
    return () => {
      if (currentTarget) {
        observer.unobserve(currentTarget);
      }
    };
  }, [observerTarget, onNextPage]);

  if (!patients.length) {
    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={columns.length}>
            Aún no tienes pacientes
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  return (
    <TableBody>
      {patients.map((patient, index) => (
        <TableRow
          key={`patients-table-body-row-${patient._id}`}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
        >
          {columns.map(({ propName, compute }) => (
            <TableCell
              sx={{ textAlign: "center" }}
              key={`patients-table-body-cell-${patient._id}-${propName}`}
              ref={index === patients.length - 1 ? observerTarget : null}
            >
              {compute?.(patient) || _.get(patient, propName, "")}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
};

interface Props {
  patients: Patient[];
  onNextPage: () => void;
}
export const PatientsTable: React.FC<Props> = ({ patients, onNextPage }) => {
  return (
    <TableContainer
      component={Paper}
      sx={{ backgroundColor: "transparent", boxShadow: "none" }}
    >
      <Table
        sx={{
          minWidth: 650,
          "& th, & td": {
            borderBottom: "3px solid rgba(2, 88, 123, 1)",
          },
        }}
        aria-label="my-patients-table"
      >
        <TableHead
          sx={{
            backgroundColor: "transparent",
          }}
        >
          <TableRow>
            {columns.map(({ propName, title }) => (
              <TableCell
                key={`table-header-cell-${propName}`}
                sx={{ textAlign: "center" }}
              >
                <Typography variant="h5" sx={{ fontWeight: 500 }}>
                  {title}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <PatientsTableBody patients={patients} onNextPage={onNextPage} />
      </Table>
    </TableContainer>
  );
};
