import momentTz from "moment-timezone";
import { useCallback, useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import {
  Appointment,
  AppointmentStatus,
  AppointmentStatusSpanish,
  formatTimezonesEs,
  GetAllPagedResult,
  ModalitySpanish,
  OrderEnum,
  ProvidedServiceTypesSpanish,
  StylesEnum,
  EndpointGenerator,
} from "@mapsy/shared";

import COLORS from "constants/colors";
import { selectSessionState } from "features/session/session.slice";
import { setToken } from "utils/setToken";
import { useAppSelector } from "hooks";
import { useAxios } from "hooks/useAxios";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "components/atoms/Button";
import { ProfilePic } from "components/atoms/ProfilePic";
import { CustomLink } from "components/atoms/Link";

const MAX_APPOINTMENTS_PER_PAGE = 10;
const currentTz = momentTz.tz.guess();
const dateFormat = "DD [de] MMMM [del] YYYY";

const MyAppointmentsPatient = () => {
  const nav = useNavigate();
  const { token, profileInfo } = useAppSelector(selectSessionState);
  const { getData, errorMsg, isLoading } = useAxios();
  const [appointmentsByPage, setAppointmentsByPage] = useState<
    Record<number, Appointment[]>
  >({});
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(page);
  const [emptyAppointmentsList, setEmptyAppointmentsList] = useState(false);

  const handleClickShowReasonPage = useCallback(() => {
    nav("/reason");
  }, []);

  const fetchAppointments = useCallback(
    async (patientId: string, token: string) => {
      const endpoint = EndpointGenerator.AppointmentAPI.byPatient(
        {
          orderBy: "date",
          order: OrderEnum.DESC,
          page,
          limit: MAX_APPOINTMENTS_PER_PAGE,
        },
        patientId
      );

      const data: GetAllPagedResult<Appointment> = await getData(
        endpoint,
        setToken(token)
      );

      if (!data?.results) {
        return;
      }

      setAppointmentsByPage((_appointments) => ({
        ..._appointments,
        [page]: data.results,
      }));

      setMaxPage(data.totalPages);
    },
    [page]
  );

  useEffect(() => {
    if (appointmentsByPage[1] && !appointmentsByPage[1].length) {
      setEmptyAppointmentsList(true);
    }
  }, [appointmentsByPage]);

  useEffect(() => {
    const patientId = profileInfo?.id;

    if (!patientId || !token) {
      return;
    }

    fetchAppointments(patientId, token);
  }, [page, profileInfo, token]);

  const handleChangePage = useCallback(() => {
    if (isLoading) {
      return;
    }

    if (page >= maxPage) {
      return;
    }

    setPage(page + 1);
  }, [maxPage, page, isLoading]);

  return (
    <>
      <Container
        maxWidth="md"
        sx={{
          px: { xs: 1.5, md: 3 },
          py: { xs: 2, md: 3 },
          position: "relative",
        }}
      >
        <Box>
          <Grid container sx={{ flexDirection: "column", gap: 3 }}>
            <Grid
              item
              md={8}
              xs={12}
              sx={{
                borderBottom: `solid 3px ${COLORS.BLUE_1}`,
                my: 2,
              }}
            >
              <Typography variant="h2" sx={{ mb: 1 }}>
                Mis citas
              </Typography>
            </Grid>
            {errorMsg && (
              <Grid xs={12}>
                <Typography variant="h5" sx={{ mb: 1, color: COLORS.TEXT_RED }}>
                  {errorMsg}
                </Typography>
              </Grid>
            )}
            {emptyAppointmentsList ? (
              <Grid item xs={12}>
                <Box
                  sx={{
                    padding: { xs: "20px 10px", md: "40px" },
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    border: {
                      md: `1px solid ${COLORS.BLUE_1}`,
                      xs: "1px solid rgba(200, 224, 233, 0.5)",
                    },
                    boxShadow: {
                      md: "0px 2px 1px rgba(0, 0, 0, 0.05)",
                      xs: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                    },
                    borderRadius: "24px",
                    gap: { md: 4, xs: 2 },
                    textAlign: "center",
                  }}
                >
                  <Typography variant="h5">
                    Aún no has agendado una cita
                  </Typography>
                  <Typography>
                    Te invitamos a contarnos tu motivo de consulta y nuestra
                    Inteligencia Artificial lo analizará para sugerirte los
                    terapeutas más adecuados.
                  </Typography>
                  <CustomButton
                    customStyle={StylesEnum.primary}
                    children="Ingresar mi motivo de consulta"
                    onClick={handleClickShowReasonPage}
                  />
                </Box>
              </Grid>
            ) : (
              Object.values(appointmentsByPage)
                ?.flat()
                .map((appointment) => {
                  const {
                    _id,
                    therapist,
                    patient,
                    date,
                    providedService,
                    locationId,
                    appointmentStatus,
                    therapistId,
                    patientId,
                  } = appointment;
                  const location = therapist?.locations.find(
                    ({ _id }) => _id === locationId
                  );
                  const momentCurrentTzDate = momentTz(date).tz(currentTz);
                  const momentPatientTzDate =
                    patient?.timezone &&
                    patient.timezone !== currentTz &&
                    momentTz(date).tz(patient?.timezone);

                  return (
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        borderRadius: "10px",
                        border: "1px solid rgba(200, 224, 233, 0.5)",
                        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                        flexWrap: "wrap",
                        minHeight: "170px",
                        p: { md: 2, xs: 1 },
                      }}
                      key={`card-appointment-${_id}`}
                    >
                      <Grid container>
                        <Grid item md={2} xs={4}>
                          {therapist?.accountStatus && (
                            <ProfilePic
                              _id={therapistId}
                              accountStatus={therapist.accountStatus}
                            />
                          )}
                        </Grid>
                        <Grid
                          item
                          md={5}
                          xs={8}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                            px: { md: 2, xs: 1.5 },
                          }}
                        >
                          {therapist?.firstName && therapist.lastName ? (
                            <CustomLink
                              to={`/therapists/${therapistId}`}
                              underline
                            >
                              <Typography>
                                {therapist.firstName} {therapist.lastName}
                              </Typography>
                            </CustomLink>
                          ) : (
                            <Typography>Terapeuta no encontrado</Typography>
                          )}
                          {providedService && location && (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                rowGap: 1,
                              }}
                            >
                              <Typography variant="body2">
                                <Typography
                                  sx={{ color: COLORS.BLUE_1 }}
                                  component="span"
                                  variant="body2"
                                >
                                  Terapia:
                                </Typography>{" "}
                                {
                                  ProvidedServiceTypesSpanish[
                                    providedService.serviceType
                                  ]
                                }
                              </Typography>
                              <Typography variant="body2">
                                <Typography
                                  sx={{ color: COLORS.BLUE_1 }}
                                  component="span"
                                  variant="body2"
                                >
                                  Modalidad:
                                </Typography>{" "}
                                {ModalitySpanish[location.modality] ||
                                  "Modalidad no disponible"}
                              </Typography>
                              <Typography variant="body2">
                                <Typography
                                  sx={{ color: COLORS.BLUE_1 }}
                                  component="span"
                                  variant="body2"
                                >
                                  Costo:
                                </Typography>{" "}
                                ${providedService.price}{" "}
                                {providedService.currency}
                              </Typography>
                            </Box>
                          )}
                        </Grid>
                        <Grid
                          item
                          md={5}
                          xs={12}
                          sx={{
                            mt: { xs: 1 },
                            py: { xs: 1 },
                            px: { md: 2, xs: 1 },
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                            border: "0.89px solid rgba(223, 223, 223, 1)",
                            borderRadius: "8px",
                          }}
                        >
                          <Typography variant="body2">
                            <Typography
                              sx={{ color: COLORS.BLUE_1 }}
                              component="span"
                              variant="body2"
                            >
                              Fecha:
                            </Typography>{" "}
                            {momentCurrentTzDate.format(dateFormat)}
                          </Typography>
                          <Typography variant="body2">
                            <Typography
                              sx={{ color: COLORS.BLUE_1 }}
                              component="span"
                              variant="body2"
                            >
                              Hora:
                            </Typography>{" "}
                            {momentCurrentTzDate.format("HH:mm a")}{" "}
                            <Typography variant="caption">
                              ({formatTimezonesEs(currentTz)})
                            </Typography>
                          </Typography>
                          {momentPatientTzDate && patient?.timezone && (
                            <>
                              <Typography variant="body2">
                                <Typography
                                  sx={{ color: COLORS.BLUE_1 }}
                                  component="span"
                                  variant="body2"
                                >
                                  Hora (en tu zona horaria):
                                </Typography>{" "}
                                {momentPatientTzDate.format("HH:mm a")} (
                                {formatTimezonesEs(patient.timezone)})
                              </Typography>
                            </>
                          )}
                          <Typography variant="body2">
                            <Typography
                              sx={{ color: COLORS.BLUE_1 }}
                              component="span"
                              variant="body2"
                            >
                              Status:
                            </Typography>{" "}
                            {appointmentStatus !== undefined
                              ? `Cita ${AppointmentStatusSpanish[
                                  appointmentStatus
                                ].toLocaleLowerCase()}`
                              : "Status no disponible"}
                          </Typography>
                        </Grid>
                        {appointmentStatus === AppointmentStatus.Done &&
                          patientId &&
                          therapistId &&
                          therapist && (
                            <>
                              <Grid item xs={false} md={8}></Grid>
                              <Grid
                                item
                                xs={12}
                                md={4}
                                sx={{
                                  px: { md: 2, xs: 1 },
                                }}
                              >
                                <CustomLink
                                  to={`/patient/review?patientId=${patientId}&therapistId=${therapistId}`}
                                  underline
                                >
                                  <Typography
                                    sx={{ fontWeight: 500, pl: { md: 2 } }}
                                    variant="caption"
                                  >
                                    Agrega una opinión del terapeuta
                                  </Typography>
                                </CustomLink>
                              </Grid>
                            </>
                          )}
                      </Grid>
                    </Grid>
                  );
                })
            )}
            {isLoading && (
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <CircularProgress sx={{ color: COLORS.BLUE_1 }} />
              </Grid>
            )}
            {page < maxPage && (
              <Grid
                item
                xs={12}
                sx={{
                  my: 2,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  onClick={handleChangePage}
                  sx={{
                    my: 1,
                    textAlign: "center",
                    color: COLORS.BLUE_1,
                    cursor: "pointer",
                    ":hover": {
                      color: COLORS.BLUE_2,
                    },
                  }}
                >
                  Mostrar más
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default MyAppointmentsPatient;
